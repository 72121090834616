import {Component, OnInit} from '@angular/core';
import {LadenDialogComponent} from '../ui/dialog/laden-dialog/laden-dialog.component';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {ImageDataType} from '../../enums/ImageDataType';
import {CookieStorageService} from '../../services/cookie-storage/cookie-storage.service';
import {EventEmitterService} from '../../services/event-emitter/event-emitter.service';
import {Location} from '@angular/common';

@Component({
  selector: 'app-page-object',
  template: '',
})
export class PageObjectComponent implements OnInit {

  private ladenDialogId = 'laden-dialog';

  constructor(
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    private cookieStorage: CookieStorageService,
    private eventemitter: EventEmitterService,
  ) {
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      let dialogRef: MatDialogRef<LadenDialogComponent, any>;
      const ladenId = parseInt(params.ladenId, 10);
      const name = params.name;
      const bildId = params.bildId ? parseInt(params.bildId, 10) - 1 : 0;
      if (ladenId) {
        if (ladenId > 0) {
          const type: ImageDataType = params.typ as ImageDataType;
          const dialogData = {
            laden: {id: ladenId},
            type,
            currentIndex: bildId,
          };
          dialogRef = this.dialog.getDialogById(this.ladenDialogId);
          if (dialogRef) {
            dialogRef.componentInstance.data = dialogData;
          } else {
            dialogRef = this.dialog.open(LadenDialogComponent, {
              id: this.ladenDialogId,
              data: dialogData,
              autoFocus: false
            });
          }
        } else {
          const index = parseInt(params.typ, 10);
          const dialogData = {
            laden: {id: ladenId},
            localUrls: true,
            localUrlsName: name,
            selectedLocalIndex: index
          };
          dialogRef = this.dialog.getDialogById(this.ladenDialogId);
          if (dialogRef) {
            dialogRef.componentInstance.data = dialogData;
          } else {
            dialogRef = this.dialog.open(LadenDialogComponent, {
              id: this.ladenDialogId,
              data: dialogData,
              autoFocus: false
            });
          }
        }
        dialogRef.afterClosed().subscribe(result => {
          this.router.navigate(['/'], {});
        });
      }

      if (!this.cookieStorage.hasAgreed) {
        this.eventemitter.emitCookiePopup();
      }
    });
  }

  canDeactivate() {
    return true;
  }
}
