import {Component, Inject, OnInit, Renderer2} from '@angular/core';
import smoothscroll from 'smoothscroll-polyfill';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import {EventEmitterService} from './services/event-emitter/event-emitter.service';
import {DOCUMENT} from '@angular/common';
import {CookieStorageService} from './services/cookie-storage/cookie-storage.service';
import {NavigationEnd, Router} from '@angular/router';

declare let gtag: (name: string, id: string, data: any) => void;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  noScroll = false;

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private cookieService: CookieStorageService,
    private eventEmitterService: EventEmitterService,
    private renderer2: Renderer2,
    @Inject(DOCUMENT) private document: Document,
    private router: Router,
  ) {
    const urlPrefix = '..';
    this.matIconRegistry.addSvgIcon('gelbe_seiten',
      this.domSanitizer.bypassSecurityTrustResourceUrl(urlPrefix + '/assets/images/footer/gelbe-seiten.svg'));
    this.matIconRegistry.addSvgIcon('facebook',
      this.domSanitizer.bypassSecurityTrustResourceUrl(urlPrefix + '/assets/images/footer/facebook.svg'));

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd && this.cookieService.hasAgreed) {
        gtag('config', 'UA-5432202-1',
          {
            page_path: event.urlAfterRedirects
          }
        );
      }
    });
  }

  ngOnInit() {
    smoothscroll.polyfill();
    this.eventEmitterService.disableScroll.subscribe(disableScroll => {
      this.noScroll = disableScroll;
    });
    this.eventEmitterService.activateGoogleAnalytics.subscribe(() => {
      this.insertGoogleAnalyticsScripts();
    });
    if (this.cookieService.hasAgreed) {
      this.insertGoogleAnalyticsScripts();
    }
  }

  private insertGoogleAnalyticsScripts() {
    const script1 = this.renderer2.createElement('script');
    script1.async = true;
    script1.src = 'https://www.googletagmanager.com/gtag/js?id=UA-5432202-1';
    this.renderer2.appendChild(this.document.head, script1);

    const script2 = this.renderer2.createElement('script');
    script2.text = `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('consent', 'default', {
            ad_user_data: 'granted',
            ad_personalization: 'granted',
            ad_storage: 'granted',
            analytics_storage: 'granted',
            ad_user_data: 'granted',
            ad_personalization: 'granted'
          });
          gtag('js', new Date());
          gtag('config', 'UA-5432202-1');
      `;
    this.renderer2.appendChild(this.document.head, script2);
  }

}
